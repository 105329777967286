import { React, useEffect, useState } from 'react';
import ServiceShortCard from './ServiceShortCard';
import ServiceFocusCard from './ServiceFocusCard';
import { useDispatch, useSelector } from 'react-redux';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { setServiceCategory } from '../states/navigationState';
import digitalLogo from '../assets/logo/digitalLogoSM.png';
import paperLogo from '../assets/logo/paperLogoSM.png';
import eventsLogo from '../assets/logo/eventsLogoSM.png';


const _OurServices = () => {

    const isIta = useSelector((state) => state.createUser.languageIta);

    const category = useSelector((state) => state.navigation.serviceCategory);
    const dispatch = useDispatch();

    const categoryTranslation = () => {
        if (category === "All Services") {
            if (isIta) { return "Tutti i Servizi" }
        }
        if (category === "Digital") {
            if (isIta) { return "Digitale" }
        }
        if (category === "Paper") {
            if (isIta) { return "Riviste" }
        }
        if (category === "Events") {
            if (isIta) { return "Eventi" }
        }
        if (!isIta) { return category }
    }

    return (

        <div>
            <div className='my-2 text-center'>
                <DropdownButton
                    as={ButtonGroup}
                    key={0}
                    id="dropdown-button-drop-0"
                    size="sm"
                    variant="light"
                    title={categoryTranslation()}
                >
                    <Dropdown.Item key="1" onClick={() => dispatch(setServiceCategory("All Services"))}>
                        {
                            isIta ?
                                <div>Tutti i servizi<img className='ms-2' src={digitalLogo} alt="" style={{ height: "20px" }} /><img className='ms-1' src={eventsLogo} alt="" style={{ height: "20px" }} /><img className='ms-1' src={paperLogo} alt="" style={{ height: "20px" }} /></div>
                                : <div>All Services<img className='ms-2' src={digitalLogo} alt="" style={{ height: "20px" }} /><img className='ms-1' src={eventsLogo} alt="" style={{ height: "20px" }} /><img className='ms-1' src={paperLogo} alt="" style={{ height: "20px" }} /></div>
                        }
                    </Dropdown.Item>
                    <Dropdown.Item key="2" onClick={() => dispatch(setServiceCategory("Digital"))}>
                        {
                            isIta ?
                                <div>Digitale<img className='ms-2' src={digitalLogo} alt="" style={{ height: "20px" }} /></div>
                                : <div>Digital<img className='ms-2' src={digitalLogo} alt="" style={{ height: "20px" }} /></div>
                        }
                    </Dropdown.Item>
                    <Dropdown.Item key="3" onClick={() => dispatch(setServiceCategory("Paper"))}>
                        {
                            isIta ?
                                <div>Carta<img className='ms-2' src={paperLogo} alt="" style={{ height: "20px" }} /></div>
                                : <div>Paper<img className='ms-2' src={paperLogo} alt="" style={{ height: "20px" }} /></div>
                        }
                    </Dropdown.Item>
                    <Dropdown.Item key="4" onClick={() => dispatch(setServiceCategory("Events"))}>
                        {
                            isIta ?
                                <div>Eventi<img className='ms-2' src={eventsLogo} alt="" style={{ height: "20px" }} /></div>
                                : <div>Events<img className='ms-2' src={eventsLogo} alt="" style={{ height: "20px" }} /></div>
                        }
                    </Dropdown.Item>
                </DropdownButton>
            </div>

            <ServiceShortCard />

        </div>

    )
}

export default _OurServices