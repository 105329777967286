import { React, useEffect, useState } from 'react';
import { CircularProgressBar } from "react-percentage-bar";
import { LinearProgressBar } from "react-percentage-bar";/* https://www.npmjs.com/package/react-percentage-bar */
import { nanoid } from 'nanoid';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useSelector } from 'react-redux';


const DatabaseStats = ({ dbStats }) => {

    const isIta = useSelector((state) => state.createUser.languageIta);
    const [typeDB, setTypeDB] = useState([0, isIta ? "DB Dentale" : "Dental DB"]);

    return (
        <div>
            <div className='d-flex align-items-center gap-3'>
                <h3 className='fw-normal text-transparent' >Statistics</h3>
                <div className='shadow-sm dbButton'>
                    <DropdownButton
                        as={ButtonGroup}
                        key={0}
                        id="dropdown-button-drop-0"
                        size="sm"
                        variant="light"
                        title={typeDB[1]}
                    >
                        <Dropdown.Item key="1" onClick={() => setTypeDB([0, isIta ? "DB Dentale" : "Dental DB"])}>{isIta ? "DB Dentale" : "Dental DB"}</Dropdown.Item>
                        <Dropdown.Item key="2" onClick={() => setTypeDB([1, isIta ? "DB Medicale" : "Medical DB"])}>{isIta ? "DB Medicale" : "Medical DB"}</Dropdown.Item>
                        <Dropdown.Item key="3" onClick={() => setTypeDB([2, isIta ? "DB Dentale Italia" : "Dental Italy Database"])}>{isIta ? "DB Dentale Italia" : "Dental Italy Database"}</Dropdown.Item>
                        <Dropdown.Item key="4" onClick={() => setTypeDB([3, isIta ? "DB Dentisti Italia" : "Dentists Italy DB"])}>{isIta ? "DB Dentisti Italia" : "Dentists Italy DB"}</Dropdown.Item>
                        <Dropdown.Item key="5" onClick={() => setTypeDB([4, isIta ? "DB Odontotecnici Italia" : "Technicians Italy DB"])}>{isIta ? "DB Odontotecnici Italia" : "Technicians Italy DB"}</Dropdown.Item>
                        <Dropdown.Item key="6" onClick={() => setTypeDB([5, isIta ? "DB ASO Italia" : "ASO Italy DB"])}>{isIta ? "DB ASO Italia" : "ASO Italy DB"}</Dropdown.Item>
                    </DropdownButton>
                </div>
            </div>

            <div>
                {
                    <div>
                        <div className='mt-1'>
                            <div className='d-flex align-items-center rounded-5' style={{ border: "6px solid #17365d", width: "100%", height: "10px" }}>
                                <div className='rounded-5' style={{ border: "2px solid #e82283", height: "3px", width: `${Math.round((dbStats[typeDB[0]].nContacts / 70000) * 100)}%` }}></div>
                            </div>
                            <p className='mb-0'><b>{Math.round((dbStats[typeDB[0]].nContacts / 70000) * 1000) / 10} % </b><i>{isIta?"del nostro Database":"of our Database"}</i></p>
                        </div>
                        {
                            isIta?
                            <i className='mb-2'> *media calcolata su <b>{dbStats[typeDB[0]].nSent / 1000}K</b> invii</i>
                            :<i className='mb-2'> *average made on <b>{dbStats[typeDB[0]].nSent / 1000}K</b> submissions</i>
                        }
                    </div>
                }
            </div>

            <div className='my-4 d-flex justify-content-center gap-3 flex-wrap'>

                {
                    dbStats[typeDB[0]].stats.map((el, index) => {

                        return <div className='d-flex justify-content-center'>
                            <div className='d-flex flex-column align-items-center'>
                                <CircularProgressBar
                                    key={nanoid()}
                                    size={".2rem"}
                                    radius={"2rem"}
                                    roundLineCap={true}
                                    styles="solid"
                                    text={index === 3 ? "up to" : null}
                                    color={el.color}
                                    percentage={el.percentage}
                                />
                                <i>{el.description}</i>
                            </div>
                        </div>

                    })
                }
            </div>
        </div>
    )
}

export default DatabaseStats