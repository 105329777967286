import React, { useEffect } from 'react';
import MainLayout from '../../layouts/MainLayout';
import globe from '../../assets/images/coExhibition/globe.png';
import bgTrade from '../../assets/images/coExhibition/bgTradeShow.jpg';
import booth from '../../assets/images/coExhibition/ticket-office.png';
import business from '../../assets/images/coExhibition/business.jpg';
import expo from '../../assets/images/coExhibition/expo.jpg';
import locker from '../../assets/images/coExhibition/lockers.png';
import meetingArea from '../../assets/images/coExhibition/meeting.png';
import showcase from '../../assets/images/coExhibition/showcase.png';
import assistant from '../../assets/images/coExhibition/secretary.png';
import { useSelector } from 'react-redux';

const CoExhibitionPage = () => {

  const isIta = useSelector((state) => state.createUser.languageIta);




  return (
    <MainLayout>
      <div className='bg-light' style={{ maxHeight: "calc(100vh - 59px)", overflowY: "scroll" }}>

        <div className=' text-center myBgImgCover' style={{ backgroundImage: `url(${bgTrade})` }}>
          <div className='px-4 py-5 myBgTransparent60 text-light'>
            <h1 className='montserrat-alternates-bold'>{isIta ? "Coesposizione" : "Trade Show Co-Exhibition"}</h1>

            {
              isIta ?
                <h2 className='font-nycd text-info my-5'>FAI UN PASSO SOTTO I RIFLETTORI</h2>
                : <h2 className='font-nycd text-info my-5'>TAKE A STEP INTO THE SPOTLIGHT</h2>
            }
            <h3 className='fw-light'>{
              isIta ?
                <div>I posti sono limitati. Non perdere l'occasione di essere al centro della scena al prossimo grande evento! </div>
                : <div>Space is limited. Don’t miss the chance to take center stage at the next big event! </div>
            }
            </h3>
          </div>
        </div>

        <div className=' myBgImgCover' style={{ backgroundImage: `url(${business})` }}>
          <div className='d-flex flex-column align-items-center myLinearGradientDark px-4 py-5 text-light'>
            <div className='spacerLg'>{/* adaptive spacer */}</div>
            <div className='myBorderWhite myMaxWidth1000 my-5 p-5 rounded-5 myBgTransparent60'>
              <h2 className='text-center'>
                {
                  isIta ?
                    <div>Venite con noi alle <b>principali fiere internazionali del settore dentale e medicale!</b> Diteci dove vorreste essere e noi ci occuperemo di tutto per voi.</div> :
                    <div>Come with us to the <b>major dental and medical trade shows in the world!</b> Tell us where you would like to be, and we’ll take care of everything for you.</div>
                }
              </h2>
              <div className=' pt-5 d-flex justify-content-center'>
                <img className='myMaxWidth80' src={globe} alt="img" />
              </div>
            </div>
            <div className='spacerLg'>{/* adaptive spacer */}</div>
          </div>
        </div>

        <div className='p-5'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-wrap justify-content-around gap-5'>
            <img className='myMaxWidth150' src={booth} alt="" />
            <div>
              <h2 className='text-secondary myMaxWidth700'>
                {
                  isIta ?
                    <div>I nostri servizi di co-esposizione ti danno la possibilità di <b>condividere uno stand con noi</b> e di presentare i tuoi prodotti in uno qualsiasi degli eventi del settore a cui intendi partecipare.</div>
                    : <div>Our co-exhibition services give you the chance of <b>sharing a booth with us</b> and showcasing your products at any of the industry events you are looking to attend.</div>
                }

              </h2>
            </div>
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5 d-flex flex-column align-items-center bg-dark text-light'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='py-5 pt-5 d-flex justify-content-center align-items-center gap-3'>
            <img className='myMaxWidth80' src={showcase} alt="img" />
            <h2 className='m-0'>+</h2>
            <img className='myMaxWidth80' src={meetingArea} alt="img" />
            <h2 className='m-0'>+</h2>
            <img className='myMaxWidth80' src={locker} alt="img" />
          </div>
          <h2 className='text-light text-center myMaxWidth1200'>
            {
              isIta ?
                <div>Il servizio include una <b>vetrina</b>, con il tuo <b>logo</b>, per esporre i tuoi prodotti, un'<b>area riunioni condivisa</b> e un <b>ripostiglio</b>.</div>
                : <div>The service includes a <b>showcase</b>, with your <b>logo</b>, to exhibit your products, a <b>shared meeting area</b> and a <b>storage room.</b></div>
            }
          </h2>

          {
            isIta ?
              <h1 className='font-nycd mt-5 mb-4 text-center fw-light text-info'>Caffè incluso!</h1>
              : <h1 className='font-nycd mt-5 mb-4 text-center fw-light text-info'>Coffè is included!</h1>
          }

          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>


        <div className='p-5' style={{ backgroundColor: "#924fc2" }}>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-wrap justify-content-around gap-5'>
            <img className='myMaxWidth300' src={expo} alt="" />
            <div>
              <h2 className='text-light myMaxWidth700'>
                {
                  isIta
                    ? <div><b>Infodent & Infomedix International</b> ha sempre accesso agli <b>stand e alle aree meglio posizionati</b>: i nostri stand sono garantiti in posizione centrale e facilmente accessibili.</div>
                    : <div><b>Infodent & Infomedix International</b> has always access to the <b>best-located booths and areas</b>: our booths are guaranteed to be central, easily accessible, and right in the spotlight.</div>
                }
              </h2>
            </div>
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='text-center' >
          <div className='p-5 text-secondary d-flex flex-column align-items-center'>
            <div className='spacerLg'>{/* adaptive spacer */}</div>
            <div className=' pt-5 d-flex justify-content-center'>
              <img className='myMaxWidth200' src={assistant} alt="img" />
            </div>
            <h2 className='my-5 myMaxWidth1200'>
              {
                isIta
                  ? <div>Ma non è tutto: venire con noi significa anche avere a disposizione un <b>assistente personale</b> del nostro team che si occuperà per te di tutte le pratiche burocratiche e di tutti gli adempimenti burocratici.</div>
                  : <div>There is more: coming with us also means having a <b>personal assistant</b> from our team that will deal with all the paperwork and take care of the bureaucratic commitments for you.</div>
              }
            </h2>
            <div className='spacerLg'>{/* adaptive spacer */}</div>
          </div>
        </div>

      </div>
    </MainLayout>
  )
}

export default CoExhibitionPage