import { nanoid } from 'nanoid';
import { React, useEffect, useState } from 'react';
import { setProductDemOnFocus } from '../states/navigationState';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getAllDemFunc } from '../states/generalDataState';
import Spinner from 'react-bootstrap/Spinner';

const DatabaseHowToDo = () => {
    const productDemOnFocus = useSelector((state) => state.navigation.productDemOnFocus);
    const allDem = useSelector((state) => state.generalData.allDem);
    const isLoadingDem = useSelector((state) => state.generalData.isLoadingDem);
    const [productDemNum, setProductDemNum] = useState(0);
    const isIta = useSelector((state) => state.createUser.languageIta);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllDemFunc())
    }, [])

    return (

        <div className='how_to_do myScrollY' style={{ height: "60vh" }}>
            <h3 className='fw-normal text-transparent' >How Use Our Database</h3>
            {
                isLoadingDem ?

                    <div className='h-50 w-100 d-flex align-items-center justify-content-center'>
                        <Spinner animation="border" variant="secondary" />
                    </div> :
                    <div>
                        {
                            !productDemOnFocus ?
                                <div className='mt-4'>
                                    {allDem && allDem.map((el, index) => {//dem list
                                        return (
                                            <div className='demCard border rounded-5 px-4 py-2 m-1' key={(nanoid())} onClick={() => { dispatch(setProductDemOnFocus(true)); setProductDemNum(index) }}>
                                                <div className='d-flex align-items-start justify-content-around'>
                                                    <p className='mb-0'>{el.name}</p>
                                                    <div className='mainIcons d-flex align-items-center ms-4'>
                                                        {
                                                            el.icons.split(",").map((item, index) => {
                                                                return <i className={`bi ${(index + 1) !== el.icons.split(",").length ? "me-2" : null} ${item}`} key={nanoid()}></i>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <hr className='my-2' />
                                                <div className='demScore d-flex justify-content-around'>
                                                    <div className='efficacyScore'>
                                                        <i className='mb-0'>efficacy</i>
                                                        <div>
                                                            {
                                                                [...Array(5)].map((item, index) => {
                                                                    if (el.efficacy_score >= index + 1) {
                                                                        return <i className="bi bi-circle-fill me-1" key={nanoid()}></i>
                                                                    } else if (el.efficacy_score > index && el.efficacy_score < index + 2) {
                                                                        return <i className="bi bi-circle-half me-1" key={nanoid()}></i>
                                                                    } else {
                                                                        return <i className="bi bi-circle me-1" key={nanoid()}></i>
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='priceScore'>
                                                        <i className='mb-0'>price</i>
                                                        <div>
                                                            {
                                                                [...Array(5)].map((item, index) => {
                                                                    if (el.price_score >= index + 1) {
                                                                        return <i className="bi bi-circle-fill me-1" key={nanoid()}></i>
                                                                    } else if (el.price_score > index && el.price_score < index + 2) {
                                                                        return <i className="bi bi-circle-half me-1" key={nanoid()}></i>
                                                                    } else {
                                                                        return <i className="bi bi-circle me-1" key={nanoid()}></i>
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div> :
                                <div className='mt-4 px-1'>
                                    {
                                        isIta ?
                                            allDem && allDem[productDemNum].description_by_steps_ita.split("|").map((el, index) => {
                                                const myText = el.split("[$]");
                                                return <div>
                                                    <h6>
                                                        <i className={`bi ${myText[0]} me-2`}></i>
                                                        {myText[1]}
                                                    </h6>
                                                    <p>{myText[2]}</p></div>
                                            })
                                            :
                                            allDem && allDem[productDemNum].description_by_steps_eng.split("|").map((el, index) => {
                                                const myText = el.split("[$]");
                                                return <div>
                                                    <h6>
                                                        <i className={`bi ${myText[0]} me-2`}></i>
                                                        {myText[1]}
                                                    </h6>
                                                    <p>{myText[2]}</p></div>
                                            })
                                    }
                                </div>
                        }
                    </div>
            }

        </div>
    )
}

export default DatabaseHowToDo