import { React, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ViewStarsRating from './ViewStarsRating';
import ReportsVisitForm from './ReportsVisitForm';
import ReportsVisitFormEdit from './ReportsVisitFormEdit';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useEffect } from 'react';
import VisitsCard from './VisitsCard';
import { getAllVisitsFunc, getAllUsersFunc } from '../states/reportState';
import Spinner from 'react-bootstrap/Spinner';
import Papa from 'papaparse';

const ReportsFairInDepth = ({ team, user }) => {

    const fairData = useSelector((state) => state.report.fairData);
    const reloadFetch = useSelector((state) => state.report.reloadFetch);
    const isEditingVisit = useSelector((state) => state.report.isEditingVisit);
    const allVisits = useSelector((state) => state.report.allVisits);
    const isLoadingVisits = useSelector((state) => state.report.isLoadingVisits);
    const allUsers = useSelector((state) => state.report.allUsers);
    const isLoadingUsers = useSelector((state) => state.report.isLoadingUsers);
    const dispatch = useDispatch();

    const [author, setAuthor] = useState("all");

    const data = Papa.unparse(allVisits);
    const fileName = 'data.csv';
    const type = 'text/csv;charset=utf-8;';

    const exportData = (data, fileName, type) => {
        // Create a link and download the file
        const blob = new Blob([data], { type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      };

    useEffect(() => {
        dispatch(getAllVisitsFunc({ input1: fairData, input2: author }));
        dispatch(getAllUsersFunc())
    }, [reloadFetch, author])


    return (


        <div>

            {isEditingVisit ? null : <ReportsVisitForm user={user} idfair={fairData.id} fairName={fairData.eventName} fairYear={fairData.year} />}

            {
                isEditingVisit ?
                    null :
                    <div className="text-light bg-dark p-4 rounded-5 border">
                        <div>
                            <div className='mb-3 d-flex justify-content-between align-items-center'>
                                <h3 className='me-3'>{fairData.eventName} {fairData.year}</h3>
                                <h5>
                                    <ViewStarsRating rate={fairData.generalRate} />
                                </h5>
                            </div>

                            <hr />

                            <h4 className='m-0'>Stand: {fairData.stand ? <i className="bi bi-check-lg text-success"></i> : <i className="bi bi-x-lg text-danger"></i>}</h4>
                            <div className='d-flex flex-wrap mt-2'>

                                <p className='m-0 me-5'>Position: <ViewStarsRating rate={fairData.positionRate} /></p>
                                <p className='mt-1 me-5'>Prev Edition: <ViewStarsRating rate={fairData.prevEditionRate} /></p>

                            </div>
                            <div>

                                <span className='m-0 bg-success text-light px-2 py-1 rounded-4 me-3'>{fairData.date.split("%")[0].slice(0, 16)}</span>
                                <span className='m-0 bg-primary text-light px-2 py-1 rounded-4'>{fairData.date.split("%")[1].slice(0, 16)}</span>

                            </div>

                            <hr />

                            <h6 className='text-light mb-1'><i className="bi bi-people-fill"></i> Partecipants:</h6>
                            <div className='text-light mb-2 d-flex flex-wrap'>
                                {fairData.partecipants && fairData.partecipants.split(",").map((el) => { return <div className='bg-info m-1 p-1 px-3 rounded-5'>{" " + team[+el - 1].name + " " + team[+el - 1].surname}</div> })}
                            </div>

                            <hr />

                            <div>
                                <p><b className='text-info'>Material Distribution:</b> <i>{fairData.materialDistribution}</i></p>
                                <hr />
                                <p><b className='text-danger'>Problems:</b> <i>{fairData.problems}</i></p>
                                <hr />
                                <p><b className='text-success'>Comments:</b> <i>{fairData.comments}</i></p>
                                <hr />
                                <p><b className='text-warning'>Notes:</b> <i>{fairData.notes}</i></p>
                            </div>

                        </div>
                    </div>
            }

            <div>
                {
                    isEditingVisit ?
                        <ReportsVisitFormEdit allVisits={allVisits} user={user} fairName={fairData.eventName} fairYear={fairData.year} /> :
                        <div>
                            <h2 className='text-light mt-4 fw-light'>Company Visits</h2>
                            <hr className='text-light mt-0' />
                    
                            <div className='mb-2 px-2 d-flex align-items-center justify-content-between'>
                                <DropdownButton id="dropdown-basic-button" title={author}>
                                    {
                                        isLoadingUsers ?
                                            <div className='py-5 d-flex align-items-center justify-content-center'>
                                                <Spinner animation="border" variant="dark" />
                                            </div> :
                                            <div className='py-2'>
                                                <Dropdown.Item className='px-3 py-2 m-0 myCursor' onClick={() => setAuthor("all")}>all</Dropdown.Item>
                                                {
                                                    allUsers && allUsers.map((el) => {
                                                        return <Dropdown.Item className='px-3 py-2 m-0 myCursor' onClick={() => setAuthor(el.email)}>{el.email}</Dropdown.Item>
                                                    })

                                                }
                                            </div>
                                    }
                                </DropdownButton>
                                
                                <div><i className="bi bi-download text-light myFontSize28 myCursor" onClick={()=>exportData(data, fileName, type)}></i></div>
                            </div>

                            <h3 className='text-light text-center mb-3 fw-normal'>{allVisits.length} <i className="bi bi-person-vcard-fill"></i></h3>

                            {
                                isLoadingVisits ?
                                    <div className='py-5 d-flex align-items-center justify-content-center'>
                                        <Spinner animation="border" variant="light" />
                                    </div> :
                                    <div className='pb-5'>
                                        {allVisits && allVisits.map((el) => {
                                            return <VisitsCard el={el} />
                                        })}
                                    </div>
                            }
                        </div>
                }
            </div>

        </div>
    )
}

export default ReportsFairInDepth