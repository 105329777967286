import React from 'react';
import MainLayout from '../../layouts/MainLayout';
import instagram from '../../assets/images/social/instagram.png';
import facebook from '../../assets/images/social/facebook.png';
import linkedin from '../../assets/images/social/linkedin.png';
import bgSocial from '../../assets/images/social/socialBg.jpg';
import network from '../../assets/images/social/network.png';
import socialMedia from '../../assets/images/social/socialMedia.jpg';
import stars from '../../assets/images/social/star.png';
import target from '../../assets/images/social/targetPeople.png';
import standard from '../../assets/images/social/gift-card-standard.png';
import advanced from '../../assets/images/social/gift-card-advanced.png';
import premium from '../../assets/images/social/gift-card-premium.png';
import tailor from '../../assets/images/social/fashion-designer.png'
import { useSelector } from 'react-redux';

const SocialMediaPage = () => {

  const isIta = useSelector((state) => state.createUser.languageIta);

  return (
    <MainLayout>
      <div className='bg-light' style={{ maxHeight: "calc(100vh - 59px)", overflowY: "scroll" }}>

        <div className=' text-center myBgImgCover' style={{ backgroundImage: `url(${bgSocial})` }}>
          <div className='px-4 py-5 myBgTransparent60 text-light'>
            <h1 className='montserrat-alternates-bold'>{
              isIta ?
                <div>Social Media</div>
                : <div>Social Media</div>
            }
            </h1>
            {
              isIta ?
                <div><h2 className='font-nycd text-info my-5'>ENTRA NELL'ARENA DIGITALE</h2></div>
                : <div><h2 className='font-nycd text-info my-5'>STEP INTO THE DIGITAL ARENA</h2></div>
            }

            <h3 className='fw-light'>{
              isIta ?
                <div>I social network sono il presente e il futuro di tutte le strategie di comunicazione digitale.</div>
                : <div>Social networks are the present and the future of all digital communication strategies.</div>
            }
            </h3>
            <div className='py-5 pt-5 d-flex justify-content-center gap-4'>
              <img className='myMaxWidth80' src={instagram} alt="img" />
              <img className='myMaxWidth80' src={facebook} alt="img" />
              <img className='myMaxWidth80' src={linkedin} alt="img" />
            </div>
          </div>
        </div>

        <div className=' myBgImgCover' style={{ backgroundImage: `url(${socialMedia})`, height: "400px" }}></div>

        <div className='p-5'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-wrap justify-content-around gap-5'>
            <img className='myMaxWidth200' src={network} alt="" />
            <div>
              <h2 className='text-secondary myMaxWidth700'>{
                isIta ?
                  <div>Il potere dei social media risiede nella loro capacità di <b>mettere in contatto la tua attività</b> con un pubblico vasto e diffuso, mentre la loro natura interattiva ti consente di creare connessioni e guadagnare la fiducia di potenziali clienti in tutto il mondo, rafforzando la fedeltà al brand e la reputazione.</div>
                  : <div>The power of social media is their ability to <b>connect your business</b> with a vast and widespread audience and their interactive nature allows you to build connections and gain the trust of potential customers worldwide, foraging brand loyalty and reputation.</div>
              }
              </h2>
            </div>
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>


        <div className='p-5 d-flex flex-column align-items-center bg-dark text-light'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <img className='myMaxWidth200 mb-5' src={target} alt="img" />
          <h2 className='text-light text-center myMaxWidth1200'>{
            isIta ?
              <div>Attraverso la comunicazione organica e la pubblicità a pagamento, puoi <b>raggiungere</b> le persone che hanno più bisogno dei tuoi servizi e prodotti. Efficaci sia nelle campagne di marketing <b>B2B che B2C</b>, i social media sono il modo più semplice e conveniente per <b>promuovere i tuoi prodotti</b></div>
              : <div>Through organic communication and paid advertising, you can <b>target</b> exactly the people who most need your services and products. Effective in both <b>B2B and B2C marketing campaigns</b>, social medias are the easiest and most convenient way to <b>promote your products.</b></div>
          }
          </h2>
          {
            isIta ?
              <div><h1 className='font-nycd mt-5 mb-4 text-center fw-light text-info'>Fai crescere il tuo business!</h1></div>
              : <div><h1 className='font-nycd mt-5 mb-4 text-center fw-light text-info'>Grow your Business!</h1></div>
          }

          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>


        <div className='p-5'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-wrap justify-content-around gap-5'>
            <img className='myMaxWidth300' src={stars} alt="" />
            <div>
              <h2 className='text-secondary myMaxWidth700'>{
                isIta ?
                  <div>I social media offrono possibilità incredibilmente diversificate: il tuo account possono essere uno showroom digitale, un'estensione del tuo sito web e della tua presenza fisica o, per i più ambiziosi, una piattaforma da cui <b>far crescere la tua attività</b> e <b>aumentare le vendite</b> collegandoti direttamente con i tuoi clienti.</div>
                  : <div>Social medias offer incredibly diversified possibilities: your accounts can be your digital showroom, an extension of your website and physical presence, or – for the most ambitious – a platform from which to <b>grow your business</b> and <b>boost sales</b> directly connecting with your clients.</div>
              }
              </h2>
            </div>
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='text-center bg-dark' >
          <div className='p-5 text-light d-flex flex-column align-items-center'>
            <div className='spacerLg'>{/* adaptive spacer */}</div>
            <div className='py-5'>
              <img className='myMaxWidth150' src={tailor} alt="img" />
            </div>
            <h2 className='my-5 myMaxWidth1200'>{
              isIta ?
                <div>Per tutti questi motivi offriamo campagne sui social media completamente <b>personalizzate</b>, in base alle esigenze dei clienti.</div>
                : <div>For all these reasons, we offer fully <b>personalized</b> social media campaigns, according to clients' needs.</div>
            }
            </h2>
            <div className='py-5 pt-5 d-flex justify-content-center gap-4'>
              <div>
                <img className='myMaxWidth80' src={standard} alt="img" />
                <h5>{
                  isIta ?
                    <div>Base</div>
                    : <div>Basic</div>
                }
                </h5>
              </div>
              <div>
                <img className='myMaxWidth80' src={advanced} alt="img" />
                <h5>{
                  isIta ?
                    <div>Avanzato</div>
                    : <div>Advanced</div>
                }
                </h5>
              </div>
              <div>
                <img className='myMaxWidth80' src={premium} alt="img" />
                <h5>{
                  isIta ?
                    <div>Premium</div>
                    : <div>Premium</div>
                }
                </h5>
              </div>
            </div>
            <h2 className='my-5 myMaxWidth1200'>{
              isIta ?
                <div>Offriamo anche vari pacchetti per soddisfare al meglio le tue esigenze. Con prezzi e vantaggi diversi, ogni pacchetto include la <b>gestione</b> delle tue pagine social, la <b>strategia dei contenuti</b> e la <b>gestione dei messaggi diretti</b> in base al brand e al tono of voice del cliente.</div>
                : <div>We also offer various packages to best fit your needs. With different prices and benefits, each package includes the <b>management</b> of your social media pages, <b>content strategy</b> and <b>direct message management</b> in accordance with client's image and tone of voice.</div>
            }
            </h2>

            <div className='spacerLg'>{/* adaptive spacer */}</div>
          </div>
        </div>

      </div>
    </MainLayout>
  )
}

export default SocialMediaPage