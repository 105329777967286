import React from 'react';
import MainLayout from '../../layouts/MainLayout';
import event1 from '../../assets/images/coursesWebinars/events_1.jpg';
import course from '../../assets/images/coursesWebinars/course.jpg';
import team from '../../assets/images/coursesWebinars/team.png';
import onlineStudy from '../../assets/images/coursesWebinars/onlineCourse.jpg';
import format4K from '../../assets/images/videoInterview/4k-film.png';
import volume from '../../assets/images/videoInterview/volume.png';
import { useSelector } from 'react-redux';

const CoursesWebinarPage = () => {
  const isIta = useSelector((state) => state.createUser.languageIta);
  return (
    <MainLayout>
      <div className='bg-light' style={{ maxHeight: "calc(100vh - 59px)", overflowY: "scroll" }}>

        <div className=' text-center myBgImgCover' style={{ backgroundImage: `url(${course})` }}>
          <div className='px-4 py-5 myBgTransparent60 text-light'>
            <h1 className='montserrat-alternates-bold'>{ 
    isIta 
    ?<div>Corsi e Webinar</div>
    :<div>Courses & Webinars</div>
  }
</h1>
            { 
    isIta 
    ?<h2 className='font-nycd text-info my-5'>TRASMETTERE LA CONOSCENZA</h2>
    :<h2 className='font-nycd text-info my-5'>PASS ON THE KNOWLEDGE</h2>
  }
            <h3 className='fw-light'>{ 
    isIta 
    ?<div>Possiamo aiutarti a organizzare corsi e webinar rivolti a professionisti del settore denatle e medicale.</div>
    :<div>We can help you organize courses and webinars aimed at dental and medical professionals.</div>
  }
 </h3>
          </div>
        </div>

        <div className='py-4 d-flex flex-column align-items-center'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='m-4 d-flex justify-content-center align-items-center flex-wrap gap-5'>
            <h2 className='text-secondary myMaxWidth700'>{ 
    isIta 
    ?<div>Corsi e webinar offrono un'incredibile opportunità di condividere la conoscenza, in quanto forniscono un'esperienza <b>strutturata, organizzata e interattiva per i partecipanti</b>. E con la nostra assistenza, non saranno più difficili da pianificare e realizzare.</div>
    :<div>Courses & Webinars offer an incredible opportunity to share the knowledge as they provide a <b>structured, organized, and interactive experience to participants</b>. And with our assistance, they will no longer be hard to plan and carry out.</div>
  }
</h2>
            <img className='w-100 myMaxWidth700' src={event1} alt="" />
          </div>
          { 
    isIta 
    ?<h1 className='font-nycd my-5 text-center fw-light text-info'>Investi nell'istruzione!</h1>
    :<h1 className='font-nycd my-5 text-center fw-light text-info'>Invest in Education!</h1>
  }
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5 bg-dark text-light'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-wrap justify-content-around gap-5'>
            <h2 className='myMaxWidth700'>{ 
    isIta 
    ?<div>Grazie alla nostra competenza e al nostro team dedicato, possiamo aiutarti a organizzare corsi e webinar rivolti a professionisti del settore dentale e medicale, nonché corsi di formazione interna al tuo personale aziendale. Offriamo <b>assistenza tecnica</b>, ma anche <b>supporto logistico e organizzativo</b> per pianificare e realizzare il workshop che hai in mente, <b>sia con che senza crediti ECM.</b></div>
    :<div>Thanks to our expertise and our dedicated team, we can help you organize courses and webinars aimed at dental and medical professionals, as well as in-house staff training within your company. We offer <b>technical assistance</b>, but also <b>logistical and organizational support</b> to plan and hold the workshop you have in mind, <b>both with and without ECM credits.</b></div>
  }
</h2>
            <img className='myMaxWidth200' src={team} alt="" />
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-4'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-wrap justify-content-around gap-5'>
            <img className='w-100 myMaxWidth700' src={onlineStudy} alt="" />
            <h2 className='text-secondary myMaxWidth700'>{ 
    isIta 
    ?<div>In base alle tue esigenze e a quelle del tuo pubblico, il corso potrà essere svolto in presenza o da remoto, sfruttando le nuove tecnologie e le piattaforme online specializzate.</div>
    :<div>According to your needs and those of your audience, the course can be held in <b>presence or remotely</b>, taking advantage of new technologies and specialized online platforms.</div>
  }
</h2>
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='text-center myBgImgCover' style={{ backgroundImage: `url(${course})` }}>
          <div className='p-5 myBgTransparent60 text-light d-flex flex-column align-items-center'>
            <div className='spacerLg'>{/* adaptive spacer */}</div>
            <div className='py-5 pt-5 d-flex justify-content-center gap-4'>
              <img className='myMaxWidth80' src={format4K} alt="img" />
              <img className='myMaxWidth80' src={volume} alt="img" />
            </div>
            <h2 className='fw-light my-5 myMaxWidth1200'>{ 
    isIta 
    ?<div>Offriamo inoltre l'assistenza necessaria per tenere corsi e formazioni in collaborazione con <b>associazioni professionali</b>.
Su richiesta <b>tutte le registrazioni possono essere rese disponibili dopo l'evento</b>, in modo da poterle condividere con i partecipanti per migliorare la loro esperienza.</div>
    :<div>We also offer the necessary assistance to hold courses and trainings in collaboration with <b>professional associations</b>.
              Upon request <b>all recordings can be made available after the event</b>, so you can share it with the participants to enhance their experience.</div>
  }

            </h2>
            <i className='mb-5 bi bi-info-circle'>{ 
    isIta 
    ?<div>Questo servizio è disponibile solo in Italia. Prezzo su richiesta e disponibilità.</div>
    :<div>This service is only available in Italy. Price upon request and availability.</div>
  }
</i>
          </div>
        </div>

      </div>
    </MainLayout>
  )
}

export default CoursesWebinarPage