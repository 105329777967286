import React from 'react';
import diagFucsiaRev from '../assets/graphics/diagonal_fucsia_reverse.png';
import diagDarkgrayRev from '../assets/graphics/diagonal_darkgray_reverse.png';
import diagDarkgrayTop from '../assets/graphics/diagonal_darkgray_top.png';
import digital from '../assets/logo/digitalLogoSM.png';
import events from '../assets/logo/eventsLogoSM.png';
import paper from '../assets/logo/paperLogoSM.png';
import { useDispatch } from 'react-redux';
import { setServiceCategory } from '../states/navigationState';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Home_OurServices = () => {

    const dispatch = useDispatch();
    const isIta = useSelector((state) => state.createUser.languageIta);

    return (
        <div className='text-light bg-transparent'>
            <div className='bg-transparent'>

                <div style={{ backgroundImage: `url(${diagFucsiaRev})`, height: "100px", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "100% 100%" }}></div>

                <div className="bg-transparent" style={{ height: "150px" }}>
                    {/* this is my spacer */}
                </div>

                <div style={{ backgroundImage: `url(${diagDarkgrayRev})`, height: "100px", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "100% 100%" }}>
                    <div className='d-flex justify-content-center'>
                        <div className='p-2 px-3 text-center' style={{ maxWidth: "700px" }}>
                            <div className='d-flex justify-content-center gap-2'>
                                <div className='myBgAcqua rounded-4 p-3'>
                                    <div className=' myFontSize38'>MAR</div>
                                </div>
                                <div className='myBgFucsia rounded-4 p-3 '>
                                    <div className=' myFontSize38'>KET</div>
                                </div>
                                <div className='myBgBlue rounded-4 p-3'>
                                    <div className=' myFontSize38'>ING</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='myBgDarkgray p-2 px-3 py-5 d-flex justify-content-center'>
                    <div style={{ maxWidth: "800px" }}>

                        <div>
                            <div className='d-flex flex-column align-items-center gap-3 pt-5'>
                                <img className='myLogo100' src={digital} alt="" />
                                <h1 className='montserrat-alternates-medium display-4 fw-bold'>{isIta ? "Digitale" : "Digital"}</h1>
                            </div>
                            {
                                isIta ?

                                    <p className='myFontSize22 myTextLightgray'><i>Il digitale non ha bisogno di presentazioni. È il futuro e il presente di ogni campagna di marketing di successo. Con i nostri strumenti di marketing digitale,
                                        <b>è possibile raggiungere distributori di prodotti dentali e medicali, dentisti e laboratori odontotecnici in tutto il mondo, con una spesa minima.</b><br />
                                        Non solo, grazie alla nostra esperienza digitale e alla nostra <b>presenza trentennale come principale Stampa di settore</b>, diffondiamo le ultime novità del settore dentale e medicale. </i>
                                    </p>
                                    : <p className='myFontSize22 myTextLightgray'><i>Digital needs no introduction. It's the future and the present of every successful marketing campaign. With our digital marketing tools,
                                        <b> it is possible to reach dental and medical dealers, dentists and dental laboratories all over the world, with minimal expense.</b><br />
                                        Not only that, thanks to our digital expertise and our <b>30-year presence as main Dental & Medical Press</b>, we spread the latest dental and medical industry news. </i>
                                    </p>

                            }
                            <Link className='noDecoration' to={"/ourservices"}><div className='d-flex align-items-baseline text-primary myCursor' onClick={() => { dispatch(setServiceCategory("Digital")) }}><p className='fw-bold'>{isIta ? "I Nostri Servizi Digitali" : "Our Digital Services"} </p><i className="bi bi-caret-right-fill ms-2"></i></div></Link>
                        </div>
                        <hr />
                        <div>
                            <div className='d-flex flex-column align-items-center gap-3 pt-5'>
                                <img className='myLogo100' src={events} alt="" />
                                <h1 className='montserrat-alternates-medium display-4 fw-bold'>{isIta ? "Eventi" : "Events"}</h1>
                            </div>
                            {
                                isIta ?
                                    <p className='myFontSize22 myTextLightgray'><i>Il business è fatto di relazioni! <b>Siamo presenti in tutte le principali fiere internazionali del settore dentale e e medicale.</b><br />
                                        Venire con noi significa avere un assistente personale che si occuperà di tutte le pratiche burocratiche per voi e non c'è bisogno di spendere una fortuna per una sede centrale, abbiamo sempre una posizione privilegiata.<br />
                                        Venite con noi e <b>espandete la vostra rete commerciale.</b>
                                    </i></p>
                                    : <p className='myFontSize22 myTextLightgray'><i>Business is made of relationships! <b>We are in all the main international dental and medical trade shows.</b><br />
                                        Coming with us means having a personal assistant to take care of all the bureaucratic paperwork for you and no need to spend a fortune for a central location, we always have a prime location.<br />
                                        Come with us and <b>expand your Business Network.</b>
                                    </i></p>
                            }
                            <Link className='noDecoration' to={"/ourservices"}><div className='d-flex align-items-baseline myTextFucsia myCursor' onClick={() => { dispatch(setServiceCategory("Events")) }}><p className='fw-bold'>{isIta ? "I Nostri Sevizi Evento" : "Our Events Services"} </p><i className="bi bi-caret-right-fill ms-2"></i></div></Link>
                        </div>
                        <hr />
                        <div>
                            <div className='d-flex flex-column align-items-center gap-3 pt-5'>
                                <img className='myLogo100' src={paper} alt="" />
                                <h1 className='montserrat-alternates-medium display-4 fw-bold'>{isIta ? "Riviste" : "Paper"}</h1>
                            </div>{
                                isIta ?
                                    <p className='myFontSize22 myTextLightgray'><i>Nel 1994 nasce Infodent International, una delle riviste leader del settore dentale da oltre 30 anni. In questo trentennio <b>hanno circolato, nelle fiere dentali di tutto il mondo, più di 2 milioni di copie cartacee!</b></i></p>
                                    : <p className='myFontSize22 myTextLightgray'><i>1994 saw the birth of Infodent International, one of the dental industry's leading magazines for more than 30 years. In this 30-year period, <b>more than 2 million hard copies have circulated at dental fairs around the world!</b></i></p>
                            }
                            <div className='bg-dark d-flex flex-wrap justify-content-center p-1 py-5 my-5 '>
                                {
                                    ["infodent", "infomedix", "tabloid", "inewsids", "inewsidem", "inews", "implantbook"].map((el) => {
                                        return <img className='img-fluid m-1' src={require(`../assets/mockUp/${el}.png`)} />
                                    })
                                }
                            </div>
                            {
                                isIta ?
                                    <p className='myFontSize22 myTextLightgray'><i>Dal 1994, Infodent Srl ha pubblicato numerose riviste sia in campo dentale che medicale, arrivando a <b>portare 7 titoli di eccellenza in tutto il mondo.</b> </i></p>
                                    : <p className='myFontSize22 myTextLightgray'><i>Since 1994, Infodent Srl has published several titles in both the dental and medical fields, now <b>carrying 7 titles of excellence worldwide.</b> </i></p>
                            }
                            <Link className='noDecoration' to={"/ourservices"}><div className='d-flex align-items-baseline text-info myCursor mt-3' onClick={() => { dispatch(setServiceCategory("Paper")) }}><p className='fw-bold'>{isIta?"Le Nostre Riviste":"Our Paper Services"} </p><i className="bi bi-caret-right-fill ms-2"></i></div></Link>
                        </div>
                        <div className="myBgDarkgray" style={{ height: "50px" }}>
                            {/* this is my spacer */}
                        </div>

                    </div>
                </div>
                <div style={{ backgroundImage: `url(${diagDarkgrayTop})`, height: "100px", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "100% 100%" }}></div>
                <div className="bg-transparent" style={{ height: "300px" }}>
                    {/* this is my spacer */}
                </div>
            </div>

        </div>
    )
}

export default Home_OurServices