import React from 'react';
import digitalLogo from '../assets/logo/digitalLogoSM.png'
import paperLogo from '../assets/logo/paperLogoSM.png'
import eventsLogo from '../assets/logo/eventsLogoSM.png'
import { useSelector } from 'react-redux';


const _OurStrategy = () => {

    const isIta = useSelector((state) => state.createUser.languageIta);

    return (
        <div style={{ maxHeight: "calc(100vh - 59px)", overflowY: "scroll" }}>
            <div className='d-flex justify-content-center' >
                <div className='myMaxWidth700'>
                    <div className='d-flex flex-column justify-content-around pt-4'>

                        <h3 className='text-light px-3 fw-light ms-3' style={{ borderLeft: "3px solid #14a7ad" }}>
                            {
                                isIta ?
                                    <div>La nostra strategia è quella di comunicare attraverso tutti e 3 i canali di comunicazione disponibili:</div>
                                    : <div>Our Strategy is to communicate through all 3 available communication channels:</div>
                            }
                        </h3>
                        <div className='d-flex justify-content-around text-light px-5 py-3'>
                            <div className='text-center' style={{ zIndex: "8" }}>
                                <p className='mb-1'>
                                    {
                                        isIta ?
                                            <div>Digitale</div>
                                            : <div>Digital</div>
                                    }
                                </p>
                                <img src={digitalLogo} alt="" style={{ height: "50px" }} />
                            </div>
                            <div className='text-center' style={{ zIndex: "8" }}>
                                <p className='mb-1'>
                                    {
                                        isIta ?
                                            <div>Eventi</div>
                                            : <div>Events</div>
                                    }
                                </p>
                                <img src={eventsLogo} alt="" style={{ height: "50px" }} />
                            </div>
                            <div className='text-center' style={{ zIndex: "8" }}>
                                <p className='mb-1'>
                                    {
                                        isIta ?
                                            <div>Riviste</div>
                                            : <div>Paper</div>
                                    }
                                </p>
                                <img src={paperLogo} alt="" style={{ height: "50px" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='myStrategy p-3 mt-4 pt-0 d-flex justify-content-center bg-info'>
                <div className='pt-5 myMaxWidth700'>

                    <div className='d-flex flex-column justify-content-around text-light fw-light py-2'>
                        <div className='p-1 py-3 d-flex align-items-center gap-3'>
                            <img className="shadow-sm" src={digitalLogo} alt="" style={{ height: "40px" }} />
                            <h6 className='mb-0 fw-light myFontSize22'>
                                {
                                    isIta ?
                                        <div>Marketing diretto via e-mail, video di prodotti e interviste video, sviluppo di siti web e app, animazione 3D e molto altro.</div>
                                        : <div>Direct E-mail Marketing, Product Video & Video Interview, Websites & App Development, 3D Animation and Much More.</div>
                                }
                            </h6>
                        </div>
                        <div className='p-1 py-3 d-flex align-items-center gap-3'>
                            <img className="shadow-sm" src={eventsLogo} alt="" style={{ height: "40px" }} />
                            <h6 className='mb-0 fw-light myFontSize22'>
                                {
                                    isIta ?
                                        <div>Co-esposizioni fieristiche, organizzazione di corsi e webinar, distribuzione di brochure e altri servizi personalizzati.</div>
                                        : <div>Trade Shows Co-exhibition, Courses & Webinar Organizzation, Leaflet Distribution and other personalized services.</div>
                                }
                            </h6>
                        </div>
                        <div className='p-1 py-3 d-flex align-items-center gap-3'>
                            <img className="shadow-sm" src={paperLogo} alt="" style={{ height: "40px" }} />
                            <h6 className='mb-0 fw-light myFontSize22'>
                                {
                                    isIta ?
                                        <div>Riviste dentali internazionali B2B (Infodent Int'l, inews AEEDC, inews IDEM e inews IDS), rivista medicali internazionale B2B (Indomedix Int'l) e rivista dentale italiana B2C (Infomedix Odontoiatria Italiana).</div>
                                        : <div>B2B Iternational Dental Magazines (Infodent Int'l, inews AEEDC, inews IDEM & inews IDS), B2B International Medical Magazine (Indomedix Int'l) & B2C Italian Dental Magazine (Infomedix Odontoiatria Italiana).</div>
                                }
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default _OurStrategy