import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import myLogo from '../assets/logo.png';
import { Link } from 'react-router-dom';
import { setLanguage } from '../states/createUserState';
import { useDispatch, useSelector } from 'react-redux';
import ita from '../assets/images/productVideo/italy.png';
import eng from '../assets/images/productVideo/united-kingdom.png';


const NavigationBar = () => {

  const dispatch = useDispatch();
  const localLang = localStorage.getItem("isIta");
  const isLocalLangIta = localLang === "true" ? true : localLang === "false" ? false : null;
  const [isHamMenuOpen, setIsHamMenuOpen] = useState(false);
  const isIta = useSelector((state) => state.createUser.languageIta);

  useEffect(() => {
    if (localLang === null) {
      navigator.language && dispatch(setLanguage(navigator.language.split("-")[0] === "it"));
      localStorage.setItem("isIta", navigator.language.split("-")[0] === "it" ? true : false)
    } else {
      dispatch(setLanguage(isLocalLangIta));
    }
  }, [isLocalLangIta]);

  const changeLanguage = () => {
    dispatch(setLanguage(!isIta));
    localStorage.setItem("isIta", !isIta)
  }


  return (
    <>
      <div className='myBgDark position-fixed w-100 d-flex align-items-center' expand="lg" style={{ zIndex: "10", height: "59px" }}>
        <Container >
          <div className='d-flex align-items-center justify-content-between py-1' >
            <div className='text-light myCursor'><Link to={"/"}><img className='m-2' src={myLogo} onClick={() => { setIsHamMenuOpen(false) }} alt="logo" style={{ height: "27px" }} /></Link></div>

            <div className='d-flex gap-1 align-items-center position-relative pe-4' onClick={changeLanguage}>

              <p className='m-0 myTextLightgray'>{isIta ? " it" : "en"}</p>
              <div className={`position-relative myCursor ${isIta ? "" : "bwFilter"}`} style={{ zIndex: `${isIta ? "1" : "0"}` }}>
                {isIta ? null : <div className='h-100 w-100 myBgTransparent30 position-absolute rounded-5' ></div>}
                <img className='myWidth20' src={ita} alt="" />
              </div>

              <div className='position-absolute myCursor' style={{ right: "8px" }} >
                <div className={`position-relative myCursor ${isIta ? "bwFilter" : ""}`}>
                  {isIta ? <div className='h-100 w-100 myBgTransparent30 position-absolute rounded-5' ></div> : null}
                  <img className='myWidth20' src={eng} alt="" />
                </div>
              </div>


            </div>
            <div className="hamButton"><i className='bi bi-grid-fill text-light m-2' onClick={() => setIsHamMenuOpen(!isHamMenuOpen)}></i></div>
          </div>
        </Container>
      </div>
      <div className="bg-transparent" style={{ height: "59px" }}>
        {/* This is my nabar spacer. It represents the navbar height. */}
      </div>
      {
        isHamMenuOpen ?

          <div className='myBgTransparent90 d-flex justify-content-end hamMenu position-absolute w-100 mt-5 pt-2 top-0 ' style={{ overflowY: "scroll", height: "calc(100vh - 48px)" }} >
            <div className='myScroll w-100' style={{ minHeight: "calc(100vh - 59px)" }}>


              <div className='pb-5'>
                <div >
                  <ul className='text-light pt-4 pe-4 text-end mb-0'>
                    <Link to={"/aboutus"}><li> <span onClick={() => { setIsHamMenuOpen(false) }}>{isIta ? "Il Team" : "Team"}</span> </li></Link>
                    <Link to={"/ourservices"}><li> <span onClick={() => { setIsHamMenuOpen(false) }}>{isIta ? "I Nostri Servizi" : "Our Services"}</span> </li></Link>
                    <Link to={"/ourdatabase"}><li> <span onClick={() => { setIsHamMenuOpen(false) }}>{isIta ? "Il Nostro Database" : "Our Database"}</span> </li></Link>
                    <Link to={"/ourstrategy"}><li> <span onClick={() => { setIsHamMenuOpen(false) }}>{isIta ? "La Nostra Strategia" : "Our Strategy"}</span> </li></Link>
                    <Link to={"/contactus"}><li> <span onClick={() => { setIsHamMenuOpen(false) }}>{isIta ? "Contatti" : "Contacts"}</span> </li></Link>
                    <Link to={"/login"}><li> <span onClick={() => { setIsHamMenuOpen(false) }}>Login</span> </li></Link>
                  </ul>
                </div>
                <hr className='text-light mx-4 my-4' />
                <div>
                  <h5 className='text-center text-light fw-light'> <i>{isIta ? "La Nostra Strategia a 360°" : "Our 360° Marketing Strategy"}</i> </h5>
                  <div className='d-flex justify-content-center'>
                    <div className='serviceBlock d-flex flex-wrap gap-2 justify-content-center pt-3 text-light px-1' style={{ maxWidth: "700px" }}>
                      <Link to={"/ourservices/papermagazines"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>{isIta ? "Riviste Cartacee" : "Paper Magazines"}</div></Link>
                      <Link to={"/ourservices/coexhibiton"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>{isIta ? "Co-esposizione Fiere" : "Trade Shows Co-exhibition"}</div></Link>
                      <Link to={"/ourservices/dem"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>{isIta ? "E-mail MArketing (DEM)" : "Direct Email Marketing (DEM)"}</div></Link>
                      <Link to={"/ourservices/leaflet"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>{isIta ? "Distribuzione Brochure" : "Leaflet Distribution"}</div></Link>
                      <Link to={"/ourservices/socialmedia"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>Social Media</div></Link>
                      <Link to={"/ourservices/animations"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>{isIta ? "Animazioni 3D" : "3D Animations"}</div></Link>
                      <Link to={"/ourservices/eventsorganizzation"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>{isIta ? "Organizzazione Eventi" : "Events Organizzation"}</div></Link>
                      <Link to={"/ourservices/videointerview"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>{isIta ? "Video Interviste" : "Video Interviews"}</div></Link>
                      <Link to={"/ourservices/courseswebinar"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>{isIta ? "Corsi & Webinar" : "Courses & Webinar"}</div></Link>
                      <Link to={"/ourservices/professionalvideo"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>{isIta ? "Video Prodotti Professionali" : "Professional Product Video"}</div></Link>
                      <Link to={"/ourservices/scientificbooks"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>{isIta ? "Libri Scientifici" : "Scientific Books"}</div></Link>
                      <Link to={"/ourservices/pressoffice"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>{isIta ? "Ufficio Stampa" : "Press Office"}</div></Link>
                      <Link to={"/ourservices/showcase"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>Real Showcase</div></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> :
          null
      }
    </>
  )
}

export default NavigationBar