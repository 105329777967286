import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    isAddingNewFair: false,
    isAddingNewCompany: false,
    isEditingFair: false,
    editingFairId: null,
    fairData: {},
    isEditingVisit: false,
    visitData: null,
    fairInDepth: false,
    partecipants: [],
    reloadFetch: true, //trigger GET request to update items
    isLoading: false,
    isLoadingVisits: false,
    isLoadingUsers: false,
    isPostingVisit: false,
    isUpdatingVisit: false,
    isPostingFair: false,
    isUpdatingFair: false,
    allFairs: [],
    allTeam: [],
    allVisits: [],
    allUsers: [],
    error: ""
};

const getAllUsersFunc = createAsyncThunk(
    "getAllUsers/fetchGetAllUsers",
    async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL_SERVER_LOCAL}/allusers`, {
                method: "GET",
                headers: { 'Authorization': `Bearer ${localStorage.getItem("tkn")}` }
            })
            const res = await response.json();
            return res
        } catch (error) {
            console.error(error);
        }
    });

const getAllFairsFunc = createAsyncThunk(
    "getAllFairs/fetchGetAllFairs",
    async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL_SERVER_LOCAL}/allfairs`, {
                method: "GET",
                headers: { 'Authorization': `Bearer ${localStorage.getItem("tkn")}` }
            })
            const res = await response.json();
            return res
        } catch (error) {
            console.error(error);
        }
    });

const getAllTeamFunc = createAsyncThunk(
    "getAllTeam/fetchGetAllTeam",
    async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL_SERVER_LOCAL}/teams`, {
                method: "GET"
            })
            const res = await response.json();
            return res
        } catch (error) {
            console.error(error);
        }
    });

const getAllVisitsFunc = createAsyncThunk(
    "getAllVisits/fetchGetAllVisits",
    async (input) => {
        const { input1, input2 } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_URL_SERVER_LOCAL}/allvisits?idfair=${input1.id}&createdBy=${input2}`, {
                method: "GET",
                headers: { 'Authorization': `Bearer ${localStorage.getItem("tkn")}` }
            })
            const res = await response.json();
            return res
        } catch (error) {
            console.error(error);
        }
    });

const createNewVisitFunc = createAsyncThunk(
    "createNewVisit/fetchCreateNewVisit",
    async (input) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL_SERVER_LOCAL}/createvisit`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("tkn")}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(input)
            });
            return await response.json()
        } catch (error) {
            console.error(error);
        }
    });

const updateNewVisitFunc = createAsyncThunk(
    "updateNewVisit/fetchUpdateNewVisit",
    async (input) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL_SERVER_LOCAL}/updatevisit`, {
                method: "PATCH",
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("tkn")}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(input)
            });
            return await response.json()
        } catch (error) {
            console.error(error);
        }
    });

const createNewFairFunc = createAsyncThunk(
    "createNewFair/fetchCreateNewFair",
    async (input) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL_SERVER_LOCAL}/createfair`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("tkn")}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(input)
            });
            return await response.json()
        } catch (error) {
            console.error(error);
        }
    });

const updateNewFairFunc = createAsyncThunk(
    "updateNewFair/fetchUpdateNewFair",
    async (input) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL_SERVER_LOCAL}/updatefair`, {
                method: "PATCH",
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("tkn")}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(input)
            });
            return await response.json()
        } catch (error) {
            console.error(error);
        }
    });



const reportSlice = createSlice({
    name: "reportSlice",
    initialState,
    reducers: {
        setAddingNewFair: (state, action) => {
            state.isAddingNewFair = action.payload
        },
        setAddingNewCompany: (state, action) => {
            state.isAddingNewCompany = action.payload
        },
        setEditingFair: (state, action) => {
            state.isEditingFair = action.payload
        },
        setEditingFairId: (state, action) => {
            state.editingFairId = action.payload
        },
        setEditingVisit: (state, action) => {
            state.isEditingVisit = action.payload
        },
        setVisitData: (state, action) => {
            state.visitData = action.payload
        },
        setFairData: (state, action) => {
            state.fairData = action.payload
        },
        setFairInDepth: (state, action) => {
            state.fairInDepth = action.payload
        },
        setMember: (state, action) => {
            const isDuplicated = () => { return state.partecipants.includes(action.payload) };
            if (isDuplicated()) {
                return
            }
            console.log(action.payload);
            state.partecipants.push(action.payload);
        },
        resetMembers: (state, action) => {
            state.partecipants = []
        },
        setReloadFetch: (state, action) => {
            state.reloadFetch = !state.reloadFetch
        }
    },
    extraReducers: (builder) => {
        /* GET ALL USERS */
        builder.addCase(getAllUsersFunc.pending, (state, action) => {
            state.isLoadingUsers = true
        });
        builder.addCase(getAllUsersFunc.fulfilled, (state, action) => {
            state.isLoadingUsers = false;
            state.allUsers = action.payload;
        });
        builder.addCase(getAllUsersFunc.rejected, (state, action) => {
            state.isLoadingUsers = false;
            state.error = "errore nella GET di allUsers"
        });
        /* GET ALL FAIRS */
        builder.addCase(getAllFairsFunc.pending, (state, action) => {
            state.isLoading = true
        });
        builder.addCase(getAllFairsFunc.fulfilled, (state, action) => {
            state.isLoading = false;
            state.allFairs = action.payload;
        });
        builder.addCase(getAllFairsFunc.rejected, (state, action) => {
            state.isLoading = false;
            state.error = "errore nella GET di allFairs"
        });
        /* GET ALL TEAM */
        builder.addCase(getAllTeamFunc.pending, (state, action) => {
            state.isLoading = true
        });
        builder.addCase(getAllTeamFunc.fulfilled, (state, action) => {
            state.isLoading = false;
            state.allTeam = action.payload;
        });
        builder.addCase(getAllTeamFunc.rejected, (state, action) => {
            state.isLoading = false;
            state.error = "errore nella GET di allTeam"
        });
        /* GET ALL VISITS */
        builder.addCase(getAllVisitsFunc.pending, (state, action) => {
            state.isLoadingVisits = true
        });
        builder.addCase(getAllVisitsFunc.fulfilled, (state, action) => {
            state.isLoadingVisits = false;
            state.allVisits = action.payload;
        });
        builder.addCase(getAllVisitsFunc.rejected, (state, action) => {
            state.isLoadingVisits = false;
            state.error = "errore nella GET di allVisits"
        });
        /* CREATE NEW VISIT */
        builder.addCase(createNewVisitFunc.pending, (state, action) => {
            state.isPostingVisit = true
        });
        builder.addCase(createNewVisitFunc.fulfilled, (state, action) => {
            state.isPostingVisit = false;
        });
        builder.addCase(createNewVisitFunc.rejected, (state, action) => {
            state.isPostingVisit = false;
            state.error = "errore nella creazione di una visit"
        });
        /* UPDATE VISIT */
        builder.addCase(updateNewVisitFunc.pending, (state, action) => {
            state.isUpdatingVisit = true
        });
        builder.addCase(updateNewVisitFunc.fulfilled, (state, action) => {
            state.isUpdatingVisit = false;
        });
        builder.addCase(updateNewVisitFunc.rejected, (state, action) => {
            state.isUpdatingVisit = false;
            state.error = "errore nell'aggironamento di una visit"
        });
        /* CREATE NEW FAIR */
        builder.addCase(createNewFairFunc.pending, (state, action) => {
            state.isPostingFair = true
        });
        builder.addCase(createNewFairFunc.fulfilled, (state, action) => {
            state.isPostingFair = false;
        });
        builder.addCase(createNewFairFunc.rejected, (state, action) => {
            state.isPostingFair = false;
            state.error = "errore nell'aggiornamento di una fiera"
        });
        /* UPDATE FAIR */
        builder.addCase(updateNewFairFunc.pending, (state, action) => {
            state.isUpdatingFair = true
        });
        builder.addCase(updateNewFairFunc.fulfilled, (state, action) => {
            state.isUpdatingFair = false;
        });
        builder.addCase(updateNewFairFunc.rejected, (state, action) => {
            state.isUpdatingFair = false;
            state.error = "errore nell'aggiornamento di una fiera"
        });
    }
})




export const { setAddingNewCompany, setAddingNewFair, setEditingFair, setEditingFairId,
    setFairData, setFairInDepth, setMember, resetMembers, setReloadFetch, setEditingVisit,
    setVisitData } = reportSlice.actions;
export default reportSlice.reducer;
export {
    getAllFairsFunc, getAllTeamFunc, getAllVisitsFunc, createNewVisitFunc,
    updateNewVisitFunc, createNewFairFunc, updateNewFairFunc, getAllUsersFunc
}