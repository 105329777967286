import { React, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import emagazineJson from '../../assets/json/emagazines.json';
import mic from '../../assets/images/pressOffice/microphone.jpg';
import newsPress from '../../assets/images/pressOffice/newsletterPressOffice.jpg';
import mailbox from '../../assets/images/pressOffice/mailbox.jpg';
import { useSelector } from 'react-redux';

const PressOfficePage = () => {

  const isIta = useSelector((state) => state.createUser.languageIta);

  const [loopIndex, setLoopIndex] = useState(1);

  const counter = (input) => {
    if (loopIndex === 7) {
      setLoopIndex(0)
    } else {
      input ? setLoopIndex(loopIndex + 1) : setLoopIndex(loopIndex - 1)
    }
  }

  return (
    <MainLayout>
      <div className='bg-light' style={{ maxHeight: "calc(100vh - 59px)", overflowY: "scroll" }}>

        <div className=' text-center myBgImgCover' style={{ backgroundImage: `url(${mic})` }}>
          <div className='px-4 py-5 myBgTransparent60 text-light d-flex flex-column align-items-center'>
            <h1 className='montserrat-alternates-bold'>{
              isIta ?
                <div>Ufficio Stampa</div>
                : <div>Press Office</div>
            }
            </h1>
            {
              isIta ?
                <div><h2 className='font-nycd text-info my-5'>TROVA UNA VOCE FORTE E PROFESSIONALE</h2></div>
                : <div><h2 className='font-nycd text-info my-5'>FIND A PROFESSIONAL AND UPSTANDING VOICE</h2></div>
            }

            <h3 className='fw-light myMaxWidth1600'>{
              isIta ?
                <div>Le comunicazioni aziendali hanno trovato un nuovo alleato: i nostro ufficio stampa è uno strumento pensato per aiutarti a promuovere la tua attività nel modo più professionale e affidabile possibile. Offriamo diversi servizi per soddisfare al meglio le tue esigenze:</div>
                : <div>Corporate communications have found a new ally: our press office services are tools designed to help you promote your business in the most professional and reputable way.
                  We offer different services to best fit your needs:</div>
            }

            </h3>
          </div>
        </div>


        <div className='py-4'>
          <div className='d-flex flex-column align-items-center'>
            <div className='spacerLg'>{/* adaptive spacer */}</div>
            <h1 className=' text-center text-secondary mt-5'>{
              isIta ?
                <div>Riviste digitali</div>
                : <div>E-Magazines</div>
            }
            </h1>
            <h2 className='text-secondary fw-light mt-4 mx-5 text-center myMaxWidth1200'>{
              isIta ?
                <div>riproducendo l'aspetto e l'idea di un quotidiano, una rivista digitale è una pagina contenente diverse notizie e informazioni sulla tua attività, un modo elegante ma originale per mettere in risalto la tua azienda e i suoi prodotti.</div>
                : <div>E-magazine: replicating the look and idea of a newspaper, a digital magazine is a page containing different news and information regarding your business, a classy yet original way of highlighting your company and its products.</div>
            }
            </h2>
          </div>
          <div className='position-relative pressArrowCarousel'>
            <div className='d-flex justify-content-between'>
              {loopIndex === 0 ? <i className="bi bi-caret-left-fill ms-5 display-3 text-light" ></i> : <i className="bi bi-caret-left-fill ms-5 display-3 myCursor text-secondary" onClick={() => counter(0)} ></i>}
              <i className="bi bi-caret-right-fill me-5 display-3 myCursor text-secondary" onClick={() => counter(1)} ></i>
            </div>
          </div>
          <div className='infinite_carousel d-flex justify-content-center align-items-center'>
            {
              emagazineJson && emagazineJson.map((el, index) => {

                if ((index === loopIndex || index === loopIndex - 1 || index === loopIndex + 1)) {
                  return <div className='p-4 m-2 border' style={{ background: "#fff" }}><a href={el.url} target='_blank'><img className={`${index === loopIndex ? "myMagazineHeightMax" : "myMagazineHeightMin"}`} src={require(`../../assets/images/pressOffice/${el.imgName}`)} alt="img" /* style={{ maxHeight: `${index === loopIndex ? "400px" : "200px"}` }} */ /></a></div>
                }

              })
            }
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>


        <div className='text-center myBgImgCover' style={{ backgroundImage: `url(${newsPress})` }}>
          <div className='py-5 my-5 text-light myBgTransparent60 d-flex flex-column align-items-center'>
            <div className='spacerLg'>{/* adaptive spacer */}</div>
            <h1 className='mt-4 text-center'>{
              isIta ?
                <div className='px-2'>Ufficio Stampa Newsletter</div>
                : <div>Newsletter Press Office</div>
            }
            </h1>
            <h2 className='fw-light my-4 mx-5 text-center myMaxWidth1400'>{
              isIta ?
                <div>Se stai cercando un modo più formale e meno esplicito per pubblicizzare i tuoi prodotti, evidenziandone l'innovazione e l'importanza, la nostra newsletter “press office” è ciò di cui hai bisogno. Combinando articoli provenienti da diverse realtà, questa pubblicazione digitale assume la forma di un comunicato stampa che diffonde notizie e informazioni compilate con un approccio scientifico e fornisce uno spazio in cui promuovere la tua attività, poggiandoti su una prospettiva affidabile e istituzionale.</div>
                : <div>If you’re searching for a more formal and less explicit way to advertise your products, highlighting their complexity and innovation, our newsletter “press office” is what you need. Combining articles from various realities, this digital publication takes the form of a press release that shares news and information compiled with a scientific approach and provides a space where to promote your business moving from a reliable and institutional perspective.</div>
            }
            </h2>
            <div className='spacerLg'>{/* adaptive spacer */}</div>
          </div>
        </div>


        <div className='py-4 d-flex flex-column align-items-center'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <h1 className=' text-secondary'>{
            isIta
              ? <div className='px-2'>Comunicato stampa B2B</div>
              : <div>B2B press release</div>
          }
          </h1>
          <div className='d-flex justify-content-center align-items-center flex-wrap'>
            <h2 className='text-secondary fw-light mt-4 mx-5 myMaxWidth700'>{
              isIta ?
                <div>Il prodotto finale può essere <b>promosso</b> e <b>distribuito</b> tramite i nostri canali a un vasto pubblico, oppure consegnato a te, così potrai occuparti dei passaggi successivi in ​​base alle tue preferenze.</div>
                : <div>Taking advantage of our vast database, this service comes in the shape of a paper letter that physically delivers your message to businesses in our network, for a press release with a more traditional feel.</div>
            }
            </h2>
            <img className='w-100 mt-5 myMaxWidth700' src={mailbox} alt="" />
          </div>
        </div>

        {/* my spacer */}
        <div style={{ height: "100px" }}></div>

      </div>

    </MainLayout>
  )
}

export default PressOfficePage