import React from 'react';
import { setAddingNewFair, setEditingFair, setEditingFairId, setFairData, setFairInDepth } from '../states/reportState'
import { useDispatch } from 'react-redux';
import ViewStarsRating from './ViewStarsRating';

const FairsCard = ({ el }) => {
    const dispatch = useDispatch();
    return (
        <div className={`border ${el.visitsCompleted && el.databaseCompleted ? "border-success":"border-danger"} bg-dark text-light m-2 p-3 py-2 rounded-4 d-flex justify-content-between myCursor `}onClick={() => { dispatch(setFairData(el)); dispatch(setAddingNewFair(false)); dispatch(setFairInDepth(true)) }}>
            <div>
                <h1 className='pe-3'>{el.eventName + " " + el.year}</h1>
                <div className='d-flex gap-3'>
                    <div className='mb-2'>
                        <span className='px-2 py-1 me-2 bg-success text-white rounded-5'>{el.date.split("%")[0].slice(4, 11)}</span>
                        <span className='px-2 py-1 bg-primary text-white rounded-5'>{el.date.split("%")[1].slice(4, 16)}</span>
                    </div>
                    <div>Stand: {el.stand ? <i className="bi bi-check-lg text-success"></i> : <i className="bi bi-x-lg text-danger"></i>}</div>
                </div>
                <div className='d-flex gap-3 mt-2'>
                    <div>
                        <ViewStarsRating rate={el.generalRate} />
                    </div>
                    <div><i>visits:</i> {el.visitsCompleted?<i className="bi bi-check2-circle text-success"></i>:<i className="bi bi-exclamation-circle text-danger"></i>}</div>
                    <div><i>db:</i> {el.databaseCompleted?<i className="bi bi-check2-circle text-success"></i>:<i className="bi bi-exclamation-circle text-danger"></i>}</div>
                </div>

            </div>
            <i className="bi bi-pencil-fill text-light" onClick={() => { dispatch(setEditingFair(true)); dispatch(setEditingFairId(el.id)); dispatch(setFairData(el)) }}></i>
        </div>
    )
}

export default FairsCard