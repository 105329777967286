import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { jwtDecode } from 'jwt-decode';
import { useState } from 'react';
import ReportsFairForm from './ReportsFairForm';
import ReportsFairFormEdit from './ReportsFairFormEdit';
import FairsCard from './FairsCard';
import ReportsFairInDepth from './ReportsFairInDepth';
import { nanoid } from 'nanoid';
import { setAddingNewFair, setEditingFair, setFairInDepth, setEditingVisit, getAllFairsFunc, getAllTeamFunc } from '../states/reportState';
import { setIsLogged } from '../states/loginState';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import _Users from './_Users';

const _Reports = () => {

    const [username, setUsername] = useState("");

    const isAddingNewFair = useSelector((state) => state.report.isAddingNewFair);
    const isEditingFair = useSelector((state) => state.report.isEditingFair);
    const fairInDepth = useSelector((state) => state.report.fairInDepth);
    const reloadFetch = useSelector((state) => state.report.reloadFetch);
    const allFairs = useSelector((state) => state.report.allFairs);
    const allTeam = useSelector((state) => state.report.allTeam);
    const isLoading = useSelector((state) => state.report.isLoading);
    const isSuperUser = useSelector((state) => state.login.isSuperUser);
    const dispatch = useDispatch();

    //json web token decodification
    const mySession = () => {
        const session = localStorage.getItem("tkn");
        const decodeSession = session ? jwtDecode(session, process.env.JWT_SECRET) : null;
        if (decodeSession) {
            setUsername(decodeSession.email)
            return decodeSession
        } else {
            return
        };
    };


    useEffect(() => {
        mySession();
        dispatch(getAllFairsFunc());
        dispatch(getAllTeamFunc());
    }, [reloadFetch])


    return (
        <div className='px-2 d-flex justify-content-center' style={{ height: "calc(100vh - 59px)", overflowY: "scroll", background: "rgb(25, 25, 25, 0.7)" }}>

            {
                isLoading ?
                    <div className='h-100 w-100 d-flex align-items-center justify-content-center'>
                        <Spinner animation="border" variant="light" />
                    </div> :
                    <div className='w-100 myMaxWidth700 mb-5'>
                        <div className='pt-2'>
                            <h4 className='text-light fw-light my-2'><i>{username}</i></h4>
                            <i className="myCursor bi bi-box-arrow-left text-danger" onClick={() => { localStorage.clear(); dispatch(setIsLogged(false)) }}> logout</i>
                        </div>
                        <hr className='text-light' />
                        {isAddingNewFair || isEditingFair || fairInDepth ? <i className="bi bi-arrow-left text-light" style={{ cursor: "pointer" }} onClick={() => { dispatch(setAddingNewFair(false)); dispatch(setEditingFair(false)); dispatch(setFairInDepth(false)); dispatch(setEditingVisit(false)) }}> back</i>
                            : <i className='mt-3 me-3 bi bi-plus-lg text-light' onClick={() => { dispatch(setAddingNewFair(true)) }} style={{ cursor: "pointer" }}> Add a New Fair</i>}

                        {
                            isSuperUser ? <_Users /> : null
                        }

                        {isAddingNewFair ? <ReportsFairForm user={username} team={allTeam} /> : null}
                        {isEditingFair ? <ReportsFairFormEdit user={username} team={allTeam} /> : null}
                        <hr className='text-light' />
                        {fairInDepth && !isEditingFair && !isAddingNewFair ? <ReportsFairInDepth user={username} team={allTeam} /> : null}
                        {allFairs && !fairInDepth && allFairs.map((el) => {
                            return <FairsCard el={el} key={nanoid()} />
                        })}

                        <div style={{ height: "80px" }}>
                            {/* this is my spacer */}
                        </div>
                    </div>
            }
        </div>
    )
}

export default _Reports